import { loginRequest } from "./config";
export function handleResponse(response, msalAppInstance) {
    if (response && response.account) {
    }
    else {
        const currentAccounts = msalAppInstance.getAllAccounts();
        if (currentAccounts.length === 0) {
            msalAppInstance.loginRedirect(loginRequest);
        }
    }
}
