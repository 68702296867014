import { msalConfig } from "./msal/config";
import { handleResponse } from "./msal/msal";
import { PublicClientApplication, } from "@azure/msal-browser";
const msalAppInstance = new PublicClientApplication(msalConfig);
msalAppInstance
    .handleRedirectPromise()
    .then((response) => {
    handleResponse(response, msalAppInstance);
    window.location.replace("/overview");
});
